<template>
    <div class="store-share-a-discount">
        <h2>¿Tienes un cupón que te gustaría compartir?</h2>
        <p class="store-share-a-discount__text">
            Envíanos todas las ofertas y cupones de {{ store.name }} que encontraste para que pueda aparecer
            en esta lista y que los demás usuarios lo puedan disfrutar.
        </p>
        <NuxtLink no-prefetch :to="`/${$lang.routes.sendCoupon}`" class="store-share-a-discount__button"
            >Enviar cupón
            <img :src="$assets.primary.arrowRight" />
        </NuxtLink>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    props: {
        store: {
            type: Object as PropType<Models.Store>,
            required: true,
        },
    },
})
</script>

<style lang="postcss" scoped>
.store-share-a-discount {
    @apply rounded-xl bg-white px-8 py-6;

    h2 {
        @apply mb-3 text-lg lg:text-[28px];
    }

    &__text {
        @apply mb-3;
    }

    &__button {
        @apply flex max-w-max items-center space-x-2 text-lg font-semibold text-site-primary;

        img {
            @apply h-5 w-5;
        }
    }
}
</style>
