<script lang="ts" setup>
import type { Api } from '~/global'
import StoreDetails from './store-details.vue'
import StoresList from './stores-list.vue'
import { useRootStore } from '~/store/root'

const Route = useRoute()
const RootStore = useRootStore()

const showList = computed(() => {
    return (
        /^(popular|a|b|c|d|e|f|g|h|i|j|k|l|m|n|ñ|o|p|q|r|s|t|u|v|w|x|y|z)$/.test(
            Route.params.slug as string,
        ) || !Route.params.slug
    )
})
</script>

<template>
    <StoresList v-if="showList"></StoresList>
    <StoreDetails v-else></StoreDetails>
</template>
